import axios from 'axios';

import { API_URL } from '../../router/constants/constants';
import { PaginatedData } from '../../types/dto/common.dto';
import { CreateUpdateEventDto, EventDto } from '../../types/dto/event.dto';
import { Location } from '../../types/enum/location';

export class Events {
  async getEvents(
    startDate: string,
    endDate: string,
    page: number,
    pageSize: number,
    lecturerPin?: string,
    location?: Location,
    eventName?: string,
    classroom?: string
  ): Promise<PaginatedData<EventDto[]>> {
    const params = new URLSearchParams();
    params.append('startDate', startDate);
    params.append('endDate', endDate);
    params.append('page', page.toString());
    params.append('pageSize', pageSize.toString());
    if (lecturerPin) {
      params.append('lecturerPin', lecturerPin);
    }
    if (location) {
      params.append('location', location);
    }
    if (eventName) {
      params.append('eventName', eventName);
    }

    if (classroom) {
      params.append('classroom', classroom);
    }

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/events`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async getEventById(eventId: string): Promise<EventDto> {
    const params = new URLSearchParams();
    params.append('eventId', eventId);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/events/one`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async createEvent(lecturerPin: string, createEventDto: CreateUpdateEventDto) {
    const params = new URLSearchParams();
    params.append('lecturerPin', lecturerPin);

    return await axios({
      method: 'POST',
      url: `${API_URL}/api/admin/events`,
      responseType: 'json',
      data: createEventDto,
      params,
    });
  }

  async updateEvent(eventId: string, updateEventDto: CreateUpdateEventDto) {
    const params = new URLSearchParams();
    params.append('eventId', eventId);

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/admin/events`,
      responseType: 'json',
      data: updateEventDto,
      params,
    });
  }

  async deleteEvent(eventId: string) {
    const params = new URLSearchParams();
    params.append('eventId', eventId);

    return await axios({
      method: 'DELETE',
      url: `${API_URL}/api/admin/events`,
      responseType: 'json',
      params,
    });
  }
}
