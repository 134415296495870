import {
  Empty,
  Table,
  TablePaginationConfig,
  TableProps,
  Tag,
  notification,
} from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Server } from '../../../../../api/server-index';
import { PaginatedData } from '../../../../../types/dto/common.dto';
import { WorksheetMetaDto } from '../../../../../types/dto/worksheet.dto';
import { WorkSheetStatus } from '../../../../../types/enum/worksheet-status';
import { getTagClassName } from '../../../../../utils/get-tag-class-name';

const WorksheetMeta = () => {
  const navigate = useNavigate();
  const params = useParams();
  const worksheetId = params.worksheetId;
  const { t } = useTranslation('worksheets');
  const [loading, setLoading] = useState<boolean>(true);
  const [metadata, setMetadata] = useState<PaginatedData<WorksheetMetaDto[]>>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const tableRef = useRef<HTMLDivElement | null>(null);

  const fetchWorksheetMeta = useCallback(async () => {
    setLoading(true);
    if (worksheetId) {
      const response = await Server.Worksheets.getWorksheetMeta({
        page: currentPage,
        pageSize: 10,
        worksheetId: worksheetId,
      });
      setMetadata(response);
    } else {
      notification.error({ message: t('meta.not_available') });
      navigate(-1);
    }
  }, [currentPage, navigate, t, worksheetId]);

  useEffect(() => {
    fetchWorksheetMeta();
    setLoading(false);
  }, [fetchWorksheetMeta]);

  const handlePageChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  const metaColumns: TableProps<WorksheetMetaDto>['columns'] = [
    {
      key: 'timestamp',
      title: t('meta.timestamp'),
      dataIndex: 'createdDate',
      width: '30%',
      render: (date) => dayjs(date).format('DD.MM.YYYY. HH:mm'),
    },
    {
      key: 'status',
      title: t('meta.status'),
      dataIndex: 'status',
      width: '40%',
      render: (status) => (
        <Tag className={getTagClassName(status as WorkSheetStatus)}>
          {t(`status.${status}`)}
        </Tag>
      ),
    },
    {
      key: 'author',
      title: t('meta.author'),
      dataIndex: 'author',
      width: '30%',
      render: (author) => (
        <span>{`${author.givenName} ${author.familyName}`}</span>
      ),
    },
  ];

  return (
    <div className='mb-2' ref={tableRef}>
      <Table
        rowKey={(val) => val.id}
        loading={loading}
        dataSource={metadata?.records}
        scroll={{
          x: 800,
        }}
        columns={metaColumns}
        pagination={{
          total: metadata?.totalCount,
          pageSize: 10,
          hideOnSinglePage: true,
          onChange: () => {
            if (tableRef.current) {
              tableRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }
          },
        }}
        onChange={handlePageChange}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('common:no_data')}
            />
          ),
        }}
      />
    </div>
  );
};

export default WorksheetMeta;
