import { Empty, Flex, Table, TableProps, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Server } from '../../../../../api/server-index';
import { AuthContext } from '../../../../../context/auth-context';
import {
  WorksheetDto,
  WorksheetEventDto,
} from '../../../../../types/dto/worksheet.dto';
import { UserRoles } from '../../../../../types/enum/user-roles';
import { HasRoles } from '../../../../../utils/has-roles';

interface WorksheetEventsProps {
  worksheet: WorksheetDto;
  setWsEvents: Dispatch<SetStateAction<WorksheetEventDto[]>>;
}

const WorksheetEvents = (props: WorksheetEventsProps) => {
  const { worksheet } = props;
  const { user } = useContext(AuthContext);
  const { t } = useTranslation('worksheets');
  const [reload, setReload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [events, setEvents] = useState<WorksheetEventDto[]>([]);
  const tableRef = useRef<HTMLDivElement | null>(null);

  const fetchWorksheetEvents = useCallback(async () => {
    setLoading(true);
    const response = await Server.Worksheets.getWorksheetEvents({
      page: 1,
      pageSize: 100,
      worksheetId: worksheet.id,
    });
    const sortedEvents = response.records.sort((a, b) => {
      const aDate = dayjs(a.dateStart).format('YYYY.MM.DD/HH:mm');
      const bDate = dayjs(b.dateStart).format('YYYY.MM.DD/HH:mm');

      if (aDate !== bDate) {
        return aDate.localeCompare(bDate);
      }
      return a.overlapEvent === b.overlapEvent ? 0 : a.overlapEvent ? 1 : -1;
    });

    setEvents(sortedEvents);
    props.setWsEvents(sortedEvents);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [worksheet.id]);

  useEffect(() => {
    fetchWorksheetEvents();
    setReload(false);
  }, [fetchWorksheetEvents, reload]);

  const eventsColumns: TableProps<WorksheetEventDto>['columns'] = [
    {
      key: 'subjectName',
      title: t('edit.events.subject_name'),
      dataIndex: 'event',
      width: '20%',
      render: (event, record) => {
        let tooltip = '';
        if (record.overlapEvent) {
          tooltip = t('edit.events.double_tooltip');
        }
        if (record.splitEvent) {
          tooltip = t('edit.events.split_tooltip');
        }

        return (
          <Flex vertical>
            <Tooltip title={tooltip}>
              <span>{event.subjectName}</span>
            </Tooltip>
            {record.note ? (
              <small className='text-gray'>{record.note}</small>
            ) : (
              <></>
            )}
          </Flex>
        );
      },
    },
    {
      key: 'date',
      title: t('edit.events.duration'),
      dataIndex: 'event',
      width: '25%',
      render: (record) => {
        return record.dateStart && record.dateEnd ? (
          <Flex vertical>
            <span>{`${dayjs(record.dateStart).format('DD.MM.YYYY.')}`}</span>
            <span className='text-gray'>{`${dayjs(record.dateStart).format(
              'HH:mm'
            )} - ${dayjs(record.dateEnd).format('HH:mm')}`}</span>
          </Flex>
        ) : (
          '---'
        );
      },
    },
    {
      key: 'hours',
      title: t('edit.events.hours'),
      dataIndex: 'duration',
      width: '15%',
      render: (value, record) => {
        return record.duration ? <span>{value}</span> : '---';
      },
    },
    {
      key: 'eventType',
      title: t('edit.events.event_type.title'),
      dataIndex: 'eventType',
      width: '20%',
      render: (text) => {
        return (
          <span>
            {text ? <Tag>{t(`edit.events.event_type.${text}`)}</Tag> : '---'}
          </span>
        );
      },
    },
    {
      key: 'attendance',
      title: t('edit.events.attendance'),
      width: '5%',
      dataIndex: 'attendance',
      render: (text) => <span>{text}</span>,
    },
  ];

  if (HasRoles([UserRoles.ACCOUNTING, UserRoles.ADMINISTRATOR], user)) {
    eventsColumns.push({
      key: 'totalAmount',
      title: t('edit.events.total_amount'),
      dataIndex: 'totalAmount',
      width: '20%',
      align: 'right',
      render: (value, record) => {
        if (!record.overlapEvent) {
          return (
            <span>{`${value ? value.toFixed(2).replace('.', ',') : 0} €`}</span>
          );
        } else return '---';
      },
    });
  }

  return (
    <div ref={tableRef}>
      <h4>{t('edit.events.title')}</h4>
      <Table
        rowKey={(val) => val.id}
        loading={loading}
        dataSource={events}
        scroll={{
          x: 800,
        }}
        columns={eventsColumns}
        pagination={{
          hideOnSinglePage: true,
          total: events.length,
          pageSize: 10,
          onChange: () => {
            if (tableRef.current) {
              tableRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }
          },
        }}
        rowClassName={(record) => {
          const classes = [];

          if (record.overlapEvent) {
            classes.push('double-wsEvent-row');
          }

          if (record.splitEvent) {
            classes.push('split-wsEvent-row');
          }

          return classes.join(' ');
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('common:no_data')}
            />
          ),
        }}
      />
    </div>
  );
};

export default WorksheetEvents;
