import {
  Empty,
  InputNumber,
  Select,
  Spin,
  Table,
  TableProps,
  Tag,
  Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import { Server } from '../../../api/server-index';
import { AuthContext } from '../../../context/auth-context';
import { SearchDataSelection } from '../../../types/common/search-data-selection.type';
import { PaginatedData } from '../../../types/dto/common.dto';
import { UserDto } from '../../../types/dto/user.dto';
import { WorksheetDto } from '../../../types/dto/worksheet.dto';
import { UserRoles } from '../../../types/enum/user-roles';
import { WorkSheetStatus } from '../../../types/enum/worksheet-status';
import { getTagClassName } from '../../../utils/get-tag-class-name';
import { HasRoles, IsAdmin } from '../../../utils/has-roles';
import { setNavigationPath } from '../../../utils/navigation-params';
import FilterIcon from '../../common/icons/filter-icon.component';
import LecturerSelect from '../../common/select/lecturer-select.component';
import MainTitle from '../../common/titles/main-title.component';

const Worksheets = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('worksheets');

  const [loading, setLoading] = useState<boolean>(true);
  const [dataSelection, setDataSelection] = useState<SearchDataSelection>({
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    pageSize: searchParams.get('pageSize')
      ? Number(searchParams.get('pageSize'))
      : 10,
  });
  const { user } = useContext(AuthContext);

  const [worksheets, setWorksheets] = useState<PaginatedData<WorksheetDto[]>>();
  const [filter, setFilter] = useState<{
    status: WorkSheetStatus[];
    worksheetNr?: number;
    lecturerPin?: string;
  }>({
    status: searchParams.get('status')
      ? (searchParams.get('status')?.split(',') || []).map(
          (status) => status as WorkSheetStatus
        )
      : [],
    worksheetNr: searchParams.get('worksheetNr')
      ? Number(searchParams.get('worksheetNr'))
      : undefined,
    lecturerPin: searchParams.get('lecturerPin') ?? '',
  });

  const fetchWorksheets = useCallback(async () => {
    setLoading(true);
    const response = await Server.Worksheets.getWorksheets({
      page: dataSelection.page,
      pageSize: dataSelection.pageSize,
      status: filter.status.length > 0 ? filter.status : getDefaultStatus(user),
      worksheetNr: filter.worksheetNr,
      lecturerPin: filter.lecturerPin,
    });
    setWorksheets(response);
    setLoading(false);
  }, [dataSelection, filter, user]);

  const setSearchURL = useCallback(async () => {
    setSearchParams(setNavigationPath(filter, dataSelection));
  }, [dataSelection, filter, setSearchParams]);

  useEffect(() => {
    fetchWorksheets();
    setSearchURL();
  }, [fetchWorksheets, setSearchURL]);

  const columns: TableProps<WorksheetDto>['columns'] = [
    {
      key: 'worksheetNr',
      title: t('list.worksheetNr'),
      dataIndex: 'worksheetNr',
      width: '10%',
      filterDropdown: () => (
        <InputNumber
          placeholder={t('list.worksheetNr')}
          value={filter.worksheetNr}
          min={0}
          className='w-100'
          onChange={(value) => {
            if (value) {
              setFilter((prevData) => ({
                ...prevData,
                worksheetNr: value,
              }));
            } else {
              setFilter((prevData) => ({
                ...prevData,
                worksheetNr: undefined,
              }));
            }
            setDataSelection((prevVal) => ({
              ...prevVal,
              page: 1,
            }));
          }}
        />
      ),
      filterIcon: <FilterIcon count={filter.worksheetNr ? 1 : 0} />,
      render: (number, record) => (
        <Tooltip title={t('list.review')}>
          <Link to={`review/${record.id}?${searchParams.toString()}`}>
            {number}
          </Link>
        </Tooltip>
      ),
    },
    {
      key: 'employee',
      title: t('list.employee'),
      dataIndex: 'employeeName',
      width: '20%',
      filterDropdown: () => (
        <div className='filter-select'>
          <LecturerSelect
            selectedLecturerPin={filter.lecturerPin}
            className='w-100'
            placeholder={t('list.filter.lecturer')}
            showSearch={true}
            onChange={(value) => {
              setFilter((prevFilterData) => ({
                ...prevFilterData,
                lecturerPin: value || '',
              }));
              setDataSelection((prevVal) => ({
                ...prevVal,
                page: 1,
              }));
            }}
          />
        </div>
      ),
      filterIcon: <FilterIcon count={filter.lecturerPin ? 1 : 0} />,
      render: (text) => <span>{text}</span>,
    },
    {
      key: 'submissionDate',
      title: t('list.submitted_at'),
      dataIndex: 'submissionDate',
      width: '20%',
      render: (text) => dayjs(text).format('DD.MM.YYYY. HH:mm'),
    },
    {
      key: 'status',
      title: t('list.status'),
      dataIndex: 'status',
      width: '20%',
      filterDropdown: () => {
        const statuses = getStatusOptions(user!);
        const options = statuses.map((status) => ({
          value: status,
          label: t(`status.${status}`),
        }));
        return (
          <div className='filter-select'>
            <Select
              value={filter.status}
              mode='multiple'
              placeholder={t('list.status')}
              className='w-100'
              options={options}
              onChange={(value) => {
                setFilter((prevData) => ({ ...prevData, status: [...value] }));
                setDataSelection((prevVal) => ({
                  ...prevVal,
                  page: 1,
                }));
              }}
            />
          </div>
        );
      },
      filterIcon: <FilterIcon count={filter.status.length} />,
      render: (text) => (
        <Tag className={getTagClassName(text as WorkSheetStatus)}>
          {t(`status.${text}`)}
        </Tag>
      ),
    },
    {
      key: 'startDate',
      title: t('list.start_date'),
      dataIndex: 'startDate',
      width: '15%',
      render: (text) => dayjs(text).format('DD.MM.YYYY.'),
    },
    {
      key: 'endDate',
      title: t('list.end_date'),
      dataIndex: 'endDate',
      width: '15%',
      render: (text) => dayjs(text).format('DD.MM.YYYY.'),
    },
  ];

  return (
    <Spin spinning={loading}>
      <MainTitle text={t('list.title')} />
      <Table
        className='mt-1 mb-2'
        scroll={{
          x: 800,
        }}
        dataSource={worksheets?.records}
        rowKey={(val) => val.id}
        columns={columns}
        pagination={{
          hideOnSinglePage: true,
          total: worksheets?.totalCount,
          pageSize: dataSelection.pageSize,
          current: dataSelection.page,
          onChange: (page: number, pageSize?: number) => {
            const newPageSize = pageSize || dataSelection.pageSize;
            // Lets get back to first page on size change
            if (newPageSize !== dataSelection.pageSize) page = 1;

            setDataSelection({
              ...dataSelection,
              page,
              pageSize: newPageSize,
            });
            window.scrollTo(0, 0);
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('common:no_data')}
            />
          ),
        }}
      />
    </Spin>
  );
};

export const getStatusOptions = (user: UserDto) => {
  if (IsAdmin(user)) {
    return [
      WorkSheetStatus.IN_REVIEW_BY_PROGRAMME_COORDINATOR,
      WorkSheetStatus.REJECTED_BY_PROGRAMME_COORDINATOR,
      WorkSheetStatus.IN_REVIEW_BY_ACCOUNTING,
      WorkSheetStatus.APPROVED_BY_ACCOUNTING,
      WorkSheetStatus.REJECTED_BY_ACCOUNTING,
      WorkSheetStatus.PAYMENT_COMPLETED,
    ];
  }
  if (HasRoles([UserRoles.PROGRAMME_COORDINATOR], user)) {
    return [
      WorkSheetStatus.IN_REVIEW_BY_PROGRAMME_COORDINATOR,
      WorkSheetStatus.REJECTED_BY_PROGRAMME_COORDINATOR,
      WorkSheetStatus.IN_REVIEW_BY_ACCOUNTING,
    ];
  }

  if (HasRoles([UserRoles.ACCOUNTING], user)) {
    return [
      WorkSheetStatus.IN_REVIEW_BY_ACCOUNTING,
      WorkSheetStatus.APPROVED_BY_ACCOUNTING,
      WorkSheetStatus.REJECTED_BY_ACCOUNTING,
      WorkSheetStatus.PAYMENT_COMPLETED,
    ];
  }

  return [];
};

export const getDefaultStatus = (user?: UserDto) => {
  if (HasRoles([UserRoles.ADMINISTRATOR], user)) {
    return [
      WorkSheetStatus.IN_REVIEW_BY_PROGRAMME_COORDINATOR,
      WorkSheetStatus.REJECTED_BY_PROGRAMME_COORDINATOR,
      WorkSheetStatus.IN_REVIEW_BY_ACCOUNTING,
      WorkSheetStatus.APPROVED_BY_ACCOUNTING,
      WorkSheetStatus.REJECTED_BY_ACCOUNTING,
      WorkSheetStatus.PAYMENT_COMPLETED,
    ];
  }

  if (HasRoles([UserRoles.PROGRAMME_COORDINATOR], user)) {
    return [
      WorkSheetStatus.IN_REVIEW_BY_PROGRAMME_COORDINATOR,
      WorkSheetStatus.IN_REVIEW_BY_ACCOUNTING,
      WorkSheetStatus.REJECTED_BY_PROGRAMME_COORDINATOR,
    ];
  }

  if (HasRoles([UserRoles.ACCOUNTING], user)) {
    return [
      WorkSheetStatus.IN_REVIEW_BY_ACCOUNTING,
      WorkSheetStatus.APPROVED_BY_ACCOUNTING,
      WorkSheetStatus.REJECTED_BY_ACCOUNTING,
      WorkSheetStatus.PAYMENT_COMPLETED,
    ];
  }

  return [];
};

export default Worksheets;
