import { Empty, Flex, Select, Spin, Table, Tag } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { Server } from '../../../api/server-index';
import { AuthContext } from '../../../context/auth-context';
import { SearchDataSelection } from '../../../types/common/search-data-selection.type';
import { PaginatedData } from '../../../types/dto/common.dto';
import { UserDto } from '../../../types/dto/user.dto';
import { UserRoles } from '../../../types/enum/user-roles';
import { getTagClassName } from '../../../utils/get-tag-class-name';
import { HasRoles } from '../../../utils/has-roles';
import { setNavigationPath } from '../../../utils/navigation-params';
import ImportButton from '../../common/buttons/import-button.component';
import NewButton from '../../common/buttons/new-button.component';
import FilterIcon from '../../common/icons/filter-icon.component';
import MainTitle from '../../common/titles/main-title.component';
import ImportLecturers from './user/lecturers/import-lecturers/import-lecturers.component';
import UsersFilter from './users-filter/users-filter.component';

export function Users() {
  const { t } = useTranslation('user');
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [role, setRole] = useState<UserRoles | undefined>(
    searchParams.get('role')
      ? (searchParams.get('role') as UserRoles)
      : undefined
  );
  const tableRef = useRef<HTMLDivElement | null>(null);

  const [users, setUsers] = useState<PaginatedData<UserDto[]>>({
    page: 1,
    pageSize: 10,
    records: [],
    totalCount: 0,
  });
  const [dataSelection, setDataSelection] = useState<SearchDataSelection>({
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    pageSize: searchParams.get('pageSize')
      ? Number(searchParams.get('pageSize'))
      : 10,
    search: searchParams.get('search') ?? '',
  });
  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    const _users = await Server.User.getUsers({
      page: dataSelection.page,
      perPage: dataSelection.pageSize,
      search: dataSelection.search,
      userRole: role,
    });
    setUsers(_users);
    setLoading(false);
  }, [dataSelection, role]);

  useEffect(() => {
    fetchUsers();
    setSearchParams(setNavigationPath({ role }, dataSelection));
  }, [dataSelection, fetchUsers, role, setSearchParams]);

  const columns: ColumnsType<UserDto> = [
    {
      title: t('form.pin'),
      dataIndex: 'pin',
      key: 'pin',
      width: '10%',
      render: (value) => {
        return <Link to={`${value}?${searchParams.toString()}`}>{value}</Link>;
      },
    },
    {
      title: t('form.hrEduUid'),
      key: 'hrEduUid',
      width: '10%',
      render: (val) => val.hrEduUid,
    },
    {
      title: t('form.givenName'),
      key: 'givenName',
      width: '15%',
      render: (val) => val.givenName,
    },
    {
      title: t('form.familyName'),
      key: 'familyName',
      width: '15%',
      render: (val) => val.familyName,
    },
    {
      key: 'roles',
      title: t('form.roles'),
      dataIndex: 'roles',
      width: '15%',
      filterDropdown: () => (
        <Select
          className='filter-select'
          allowClear
          value={role}
          placeholder={t('select_role')}
          options={Object.values(UserRoles).map((role) => {
            return { key: role, value: role, label: t(`roles.${role}`) };
          })}
          onChange={(value) => {
            setRole(value);
            setDataSelection((prevVal) => ({
              ...prevVal,
              page: 1,
            }));
          }}
        />
      ),
      filterIcon: <FilterIcon count={role ? 1 : 0} />,
      render: (roles) =>
        roles.map((role: UserRoles) => (
          <Tag key={role} className={getTagClassName(role)}>
            {t(`roles.${role}`)}
          </Tag>
        )),
    },
    {
      title: t('form.email'),
      key: 'email',
      width: '30%',
      render: (val) => val.email,
    },
  ];

  return (
    <Spin spinning={loading}>
      <MainTitle text={t('users')} />
      <Flex gap={12} align='center' className='mb-1'>
        {HasRoles(
          [UserRoles.ADMINISTRATOR, UserRoles.PROGRAMME_COORDINATOR],
          user
        ) ? (
          <>
            <NewButton
              onClick={() => navigate(`new?${searchParams.toString()}`)}
            />
            <ImportButton onClick={() => setIsImportModalOpen(true)} />
          </>
        ) : (
          <></>
        )}
      </Flex>
      <div ref={tableRef}></div>
      <UsersFilter
        dataSelection={dataSelection}
        setDataSelection={setDataSelection}
      />
      <Table
        columns={columns}
        className='mt-1 mb-2'
        dataSource={users.records}
        scroll={{
          x: 800,
        }}
        rowKey={(val) => val.pin}
        pagination={{
          hideOnSinglePage: true,
          total: users?.totalCount,
          pageSize: dataSelection.pageSize,
          current: dataSelection.page,
          onChange: (page: number, pageSize?: number) => {
            const newPageSize = pageSize || dataSelection.pageSize;
            // Lets get back to first page on size change
            if (newPageSize !== dataSelection.pageSize) page = 1;

            setDataSelection({
              ...dataSelection,
              page,
              pageSize: newPageSize,
            });
            if (tableRef.current) {
              tableRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('common:no_data')}
            />
          ),
        }}
      ></Table>
      <ImportLecturers
        isImportModalOpen={isImportModalOpen}
        setIsImportModalOpen={setIsImportModalOpen}
        setLoading={setLoading}
      />
    </Spin>
  );
}
