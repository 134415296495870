import { ProgramLevel } from '../types/enum/program-level';
import { ProgramMode } from '../types/enum/program-mode';
import { RequestStatus } from '../types/enum/request-status';
import { UserRoles } from '../types/enum/user-roles';
import { WorkSheetStatus } from '../types/enum/worksheet-status';

export const getTagClassName = (
  tag: WorkSheetStatus | UserRoles | ProgramLevel | ProgramMode | RequestStatus
) => {
  switch (tag) {
    case WorkSheetStatus.PAYMENT_COMPLETED:
      return 'badge-black';
    case WorkSheetStatus.DRAFT:
      return 'badge-draft';
    case WorkSheetStatus.IN_REVIEW_BY_PROGRAMME_COORDINATOR:
      return 'badge-yellow';
    case WorkSheetStatus.APPROVED_BY_PROGRAMME_COORDINATOR:
      return 'badge-green';
    case WorkSheetStatus.REJECTED_BY_PROGRAMME_COORDINATOR:
      return 'badge-red';
    case WorkSheetStatus.IN_REVIEW_BY_ACCOUNTING:
    case RequestStatus.IN_REVIEW:
      return 'badge-second-yellow';
    case WorkSheetStatus.APPROVED_BY_ACCOUNTING:
    case RequestStatus.APPROVED:
      return 'badge-second-green';
    case WorkSheetStatus.REJECTED_BY_ACCOUNTING:
    case RequestStatus.REJECTED:
      return 'badge-second-red';
    case UserRoles.STUDENT:
    case ProgramMode.REGULAR:
      return 'badge-lightest-blue';
    case UserRoles.LECTURER:
    case ProgramMode.PART_TIME:
      return 'badge-light-blue';
    case UserRoles.ADMINISTRATOR:
    case ProgramMode.LIFELONG:
      return 'badge-darkest-blue';
    case UserRoles.PROGRAMME_COORDINATOR:
    case ProgramMode.BRIDGE:
      return 'badge-blue';
    case UserRoles.ACCOUNTING:
      return 'badge-dark-blue';
    case ProgramLevel.GRADUATE:
      return 'badge-purple';
    case ProgramLevel.UNDERGRADUATE:
      return 'badge-light-purple';
    default:
      return '';
  }
};
