import { Input, Select, Spin, Table, TableProps, Tag } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { Server } from '../../../api/server-index';
import { SearchDataSelection } from '../../../types/common/search-data-selection.type';
import { PaginatedData } from '../../../types/dto/common.dto';
import { PartnerDto } from '../../../types/dto/partner.dto';
import { PartnerType } from '../../../types/enum/partner-type';
import { setNavigationPath } from '../../../utils/navigation-params';
import NewButton from '../../common/buttons/new-button.component';
import FilterIcon from '../../common/icons/filter-icon.component';
import MainTitle from '../../common/titles/main-title.component';

const Partners = () => {
  const navigate = useNavigate();
  const tableRef = useRef<HTMLDivElement | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('partner');
  const [partners, setPartners] = useState<PaginatedData<PartnerDto[]>>();
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [dataSelection, setDataSelection] = useState<SearchDataSelection>({
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    pageSize: searchParams.get('pageSize')
      ? Number(searchParams.get('pageSize'))
      : 10,
  });
  const [filter, setFilter] = useState<{
    partnerType: PartnerType[];
    partnerPin?: string;
    partnerName?: string;
  }>({
    partnerPin: searchParams.get('partnerPin') ?? '',
    partnerName: searchParams.get('partnerName') ?? '',
    partnerType: searchParams.get('partnerType')
      ? (searchParams.get('partnerType')?.split(',') || []).map(
          (type) => type as PartnerType
        )
      : [],
  });

  const fetchPartners = useCallback(async () => {
    setLoading(true);
    const response = await Server.Partner.getPartners({
      page: dataSelection.page,
      pageSize: dataSelection.pageSize,
      partnerPin: filter.partnerPin,
      partnerName: filter.partnerName,
      partnerType:
        filter.partnerType.length > 0
          ? filter.partnerType
          : Object.values(PartnerType),
    });

    setPartners(response);
    setLoading(false);
  }, [dataSelection, filter]);

  useEffect(() => {
    fetchPartners();
    setSearchParams(setNavigationPath(filter, dataSelection));
    setReload(false);
  }, [reload, dataSelection, filter, setSearchParams, fetchPartners]);

  const columns: TableProps<PartnerDto>['columns'] = [
    {
      key: 'pin',
      title: t('form.pin'),
      dataIndex: 'pin',
      width: '20%',
      render: (pin) => (
        <Link to={`/partners/edit/${pin}?${searchParams.toString()}`}>
          {pin}
        </Link>
      ),
    },
    {
      key: 'name',
      title: t('form.name'),
      dataIndex: 'name',
      width: '50%',
      filterDropdown: () => (
        <Input
          placeholder={t('common:search')}
          value={filter.partnerName}
          className='w-100'
          onChange={(e) => {
            setFilter((prevData) => ({
              ...prevData,
              partnerName: e.target.value,
            }));
            setDataSelection({
              page: 1,
              pageSize: 10,
            });
          }}
        />
      ),
      filterIcon: () => <FilterIcon count={filter.partnerName ? 1 : 0} />,
      render: (name) => <span>{name}</span>,
    },
    {
      key: 'type',
      title: t('form.type'),
      dataIndex: 'type',
      width: '30%',
      filterDropdown: () => {
        const statuses = Object.values(PartnerType);
        const options = statuses.map((type) => ({
          value: type,
          label: t(`types.${type}`),
        }));
        return (
          <div className='filter-select'>
            <Select
              value={filter.partnerType}
              mode='multiple'
              placeholder={t('form.type')}
              className='w-100'
              options={options}
              onChange={(value) => {
                setFilter((prevData) => ({
                  ...prevData,
                  partnerType: [...value],
                }));
                setDataSelection((prevVal) => ({
                  ...prevVal,
                  page: 1,
                }));
              }}
            />
          </div>
        );
      },
      filterIcon: <FilterIcon count={filter.partnerType?.length ?? 0} />,
      render: (type) => <Tag>{t(`types.${type}`)}</Tag>,
    },
  ];

  return (
    <Spin spinning={loading}>
      <MainTitle text={t('partners')} />
      <div ref={tableRef}></div>
      <NewButton onClick={() => navigate('new')} />
      <Table
        className='mt-1 mb-2'
        dataSource={partners?.records}
        columns={columns}
        scroll={{
          x: 800,
        }}
        rowKey={(val) => val.pin}
        pagination={{
          hideOnSinglePage: true,
          total: partners?.totalCount,
          pageSize: dataSelection.pageSize,
          current: dataSelection.page,
          onChange: (page: number, pageSize?: number) => {
            const newPageSize = pageSize || dataSelection.pageSize;
            // Lets get back to first page on size change
            if (newPageSize !== dataSelection.pageSize) page = 1;

            setDataSelection({
              ...dataSelection,
              page,
              pageSize: newPageSize,
            });
            if (tableRef.current) {
              tableRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
            }
          },
        }}
      />
    </Spin>
  );
};

export default Partners;
