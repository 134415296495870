import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Server } from '../../../../../api/server-index';
import { PartnerDto } from '../../../../../types/dto/partner.dto';
import { UserDto } from '../../../../../types/dto/user.dto';
import { PartnerType } from '../../../../../types/enum/partner-type';

interface Props {
  user: UserDto;
}

export function InvoiceTo({ user }: Props) {
  const { t } = useTranslation(['pdf', 'partner']);
  const [partner, setPartner] = useState<PartnerDto>();

  useEffect(() => {
    const fetchPartner = async () => {
      const response = await Server.Partner.getPartner(user.pin);
      setPartner(response);
    };

    fetchPartner();
  }, [user.pin]);

  const styles = StyleSheet.create({
    headerContainer: {
      flexDirection: 'column',
    },
    bold: { fontWeight: 'bold' },
    column: { flexDirection: 'column' },
  });

  return (
    <View style={[styles.headerContainer, styles.bold]}>
      <Text>{`${user.givenName} ${user.familyName}`} </Text>
      {partner ? (
        <>
          <Text>
            {t('pdf:invoice_to.partner')}: {partner?.name}{' '}
            {partner.type === PartnerType.PERSON ||
            partner.type === PartnerType.CRAFT ? (
              <></>
            ) : (
              <>{t(`partner:types.${partner?.type}`)}</>
            )}
          </Text>
          {partner.address && partner.town ? (
            <Text>
              {partner?.address}, {partner.town}
            </Text>
          ) : (
            <></>
          )}
          <Text>{partner?.iban}</Text>
          <Text>{partner?.intlBankSwift}</Text>
        </>
      ) : (
        <Text>{user.email}</Text>
      )}
    </View>
  );
}
